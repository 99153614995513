import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'

import EmployerList from '@/components/EmployerComponents/EmployerList.vue'
import EmployerDetails from '@/components/EmployerComponents/EmployerDetails.vue'

import CandidateList from '@/components/CandidateComponents/CandidateList.vue'
import CandidateDetails from '@/components/CandidateComponents/CandidateDetails.vue'

import ActivejobList from '@/components/ActivejobComponents/ActivejobList.vue'
import ActivejobDetails from '@/components/ActivejobComponents/ActivejobDetails.vue'

import ArchivedjobList from '@/components/ArchivedjobComponents/ArchivedjobList.vue'
import ArchivedjobDetails from '@/components/ArchivedjobComponents/ArchivedjobDetails.vue'

import ChangePassword from '@/components/SettingsComponents/ChangePassword.vue'
import ChangeUsername from '@/components/SettingsComponents/ChangeUsername.vue'

import CourseList from '@/components/CourseComponents/CourseList.vue'
import CreateCourse from '@/components/CourseComponents/CreateCourse.vue'
import CourseDetails from '@/components/CourseComponents/CourseDetails.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import TermsConditions from '@/components/TermsConditions.vue'

import Jobsu from '@/components/Jobsu.vue'

const routes = [
  {
    name: 'Master',
    path: '/home',
    redirect: '/dashboard',
    component: () => import('../pages/layout/master.vue'),
    children: [
      {
        name: 'dashbaord',
        path: '/dashboard',
        component: () => import('../pages/dashboard.vue'),
      },
      {
        path: '/employers',
        name: 'employers',
        component: EmployerList
      },
      {
        path: '/employer_details/:employerId',
        name: 'employer_details',
        component: EmployerDetails
      },
      {
        path: '/candidates',
        name: 'candidates',
        component: CandidateList
      },
      {
        path: '/candidate_details/:userId',
        name: 'candidate_details',
        component: CandidateDetails
      },
      {
        path: '/active_jobs',
        name: 'active_jobs',
        component: ActivejobList
      },
      {
        path: '/activejob_details/:jobId',
        name: 'activejob_details',
        component: ActivejobDetails
      },
      {
        path: '/archived_jobs',
        name: 'archived_jobs',
        component: ArchivedjobList
      },
      {
        path: '/archivedjob_details/:jobId',
        name: 'archivedjob_details',
        component: ArchivedjobDetails
      },
      {
        path: '/change_password',
        name: 'change_password',
        component: ChangePassword
      },
      {
        path: '/change_username',
        name: 'change_username',
        component: ChangeUsername
      },
      {
        path: '/courses',
        name: 'courses',
        component: CourseList
      },
      {
        path: '/create_course',
        name: 'create_course',
        component: CreateCourse
      },
      {
        path: '/course_details/:id',
        name: 'course_details',
        component: CourseDetails
      },
    ]
  },
  {
    path: '/admins',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'jobsu',
    component: Jobsu
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms&conditions',
    name: 'trems&conditions',
    component: TermsConditions
  }
]

const router = createRouter({
  base: process.env.VUE_APP_PUBLICPATH,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
