<template>
  <h3 class="py-3 text-center text-3xl font-bold dark:text-dark">
    Course Details
  </h3>
  <div class="d-flex align-items-center justify-content-center">
    <div class="w-full max-w-xxl px-2">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="row">
          <div class="col-md-6 left">
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="name"
                  >
                    Company Name :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.name"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="author"
                  >
                    Author :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.author"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="banner"
                  >
                    Banner :
                  </label>
                </div>
                <div class="col-md-8">
                  <!--  -->
                  <!-- <input
                    v-model="list.banner"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  /> -->
                  <!--  -->
                  <!-- <img :src="list.banner" alt=""> -->
                  <!--  -->

                  <!-- <input
                    class="bg-info"
                    id="banner"
                    type="file"
                    accept="image/*"
                    @change="preview"
                    hidden
                  /> -->
                  <div id="img-view">
                    <!-- <div> -->
                      <!-- <img style="width:100px;height:100px" id="uploadedImg" src="https://jobsu.in/mobile/uploads/5978114140.jpg" />  -->
                      <!-- <img
                        style="width: 100px; height: 100px"
                        id="uploadedImg"
                        :src="`https://jobsu.in/mobile/uploads/${list.bannerId}.jpg`"
                      /> -->
                    <!-- </div> -->

                    <!-- new -->
                    <div class="mb-1" >
                     
                      <label
                        class="block text-gray-700 text-sm font-bold mb-2 text-start"
                        for="banner"
                        id="drop-area"
                      >
                        <input
                          id="banner"
                          type="file"
                          accept="image/*"
                          @change="preview"
                          hidden
                        />
                        <div id="img-view">
                 
                            <img
                            id="upload-img"
                              :src="`https://jobsu.in/mobile/uploads/${list.bannerId}.jpg`"
                              alt=""
                              class="img-fluid"
                            />
                        </div>
                      </label>
                      
                    </div>

                    <!-- new -->
                   

                    

                  </div>
                  <!--  -->
                  

                   <!-- new 2 -->

                 </div>
 
                  <!-- new 2 -->
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="price"
                  >
                    Price :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.price"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="email"
                  >
                    Email :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.email"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 right">
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="course_duration"
                  >
                    Course Duration :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.course_duration"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="post_duration"
                  >
                    Post Duration :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.post_duration"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="course_link"
                  >
                    Course Link :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.course_link"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="whatsapp"
                  >
                    WhatsApp Number :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.whatsapp"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="phoneNumber"
                  >
                    Phone Number :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.phone"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div></div>
      <div class="flex items-center justify-center py-4">
        <div class="mb-2 px-2">
          <button
            v-on:click="onUpdateCourse()"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            style="background: #0167ff"
          >
            Update
          </button>
        </div>
        <div class="mb-2 px-2">
          <button
            v-on:click="onDeleteCourse()"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            style="background: #0167ff"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "App",
  data() {
    return {
      isUploaded: false,
      list: {
        name: "",
        author: "",
        banner: "",
        banner2: "",
        price: "",
        course_duration: "",
        post_duration: "",
        course_link: "",
        whatsapp: "",
        email: "",
        phone: "",
      },
      list2:[]
    };
  },
   watch: {
    
  
    'list.banner2'(newBanner2) {
      this.$storage.setStorageSync('banner2', newBanner2);
    },
  },
  methods: {
    async onUpdateCourse() {
      
      this.id = this.$route.params.id;
      let params = new URLSearchParams();
      params.append("id", this.id);
      params.append("name", this.list.name);
      params.append("author", this.list.author);
      // params.append("banner", this.list.banner);
      // this.banner2 = this.banner.split(',')[1]
      params.append("banner",this.banner2);
      params.append("price", this.list.price);
      params.append("bannerId", this.list.bannerId);
      params.append("course_duration", this.list.course_duration);
      params.append("post_duration", this.list.post_duration);
      params.append("course_link", this.list.course_link);
      params.append("whatsapp", this.list.whatsapp);
      params.append("email", this.list.email);
      params.append("phone", this.list.phone);
      // console.log(this.list);
      console.log(this.banner);
      axios
        .post("https://jobsu.in/adminapis/updateCourse.php", params)
        .then((res) => {
          this.list = res.data;
          console.log("Updated")
          console.log("Updated",this.list)
          console.log("upda",res);
          this.$router.push({ path: "/courses" });
        })
        .then((response) => {
          Swal.fire("Updated!", "Record has been updated.", "success");
           console.log("hi")
          this.list2 = response
          console.log("Updated",this.list2)
          
        })
        .catch((error) => {
         
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    
    },
    async onDeleteCourse() {
      this.id = this.$route.params.id;
      let params = new URLSearchParams();
      params.append("id", this.id);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post("https://jobsu.in/adminapis/deleteCourse.php", params)
              .then(() => {
                Swal.fire("Deleted!", "Record has been deleted.", "success");
                // this.$router.push({ path: "/courses" });
              });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
    preview(e) {
      console.log("img1");
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.banner = reader.result;
        console.log("result", reader.result);
        console.log("banner1" + this.banner)
        this.banner2 = this.banner.split(',')[1]
        // this.banner2 =  localStorage.setItem('banner',this.banner)
        document.getElementById("upload-img").src = this.banner;
      };
      reader.readAsDataURL(file);
    },
     preview2(e) {
      console.log("banner2");
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.banner2 = reader.result;
        console.log("result", this.banner2);
        this.banner2 =  localStorage.setItem('banner',this.banner2)
      };
      reader.readAsDataURL(file);
    },
  },
  async mounted() {

    this.id = this.$route.params.id;
    let params = new URLSearchParams();
    params.append("id", this.id);
    const result = await axios
      .post("https://jobsu.in/adminapis/getCourse.php", params)
      .then((response) => {
        console.log("olddata",response);
        this.list = response.data;
      })
      .catch((error) => {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  },
};
</script>
<style scoped>
#drop-area {
  width: 40%;
  height: 100%;
  padding: 5px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
#drop-area2 {
  width: 40%;
  height: 100%;
  padding: 5px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
</style>
