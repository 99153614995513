<template>
  <div
    class="d-flex align-items-center justify-content-center vh-100 bg-gray-50 login"
  >
    <div class="w-full max-w-sm px-2">
      <a
        href="#"
        class="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
      >
        <img src="@/assets/logo.svg" class="img-fluid" alt="" />
      </a>
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="email"
          >
            Username
          </label>
          <input
            v-model="email"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="email"
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="password"
          >
            Password
          </label>
          <input
            v-model="password"
            class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
          />
        </div>
        <div class="flex items-center justify-center">
          <button
            v-on:click="login()"
            class="w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline loginbtn"
            type="button"
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
   methods: {
    async login() {
      axios
        .post("https://jobsu.in/adminapis/adminLogin.php", {
          email: this.email,
          password: this.password,
        },
        { 
          headers: {
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        })
        .then((res) => {
          // console.log(res);
          if(res.status == 200){
            this.$storage.setStorageSync("email", this.email);
          }
          // this.$storage.setStorageSync("usertype", res.data.type);
          // this.$storage.setStorageSync("user", res.data.adminData.userID);
          // this.$storage.setStorageSync("userID", res.data.adminData._id);
          this.$router.push({ path: "/dashboard" });
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Login Success!",
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            if(error.response.status == 400){
              Swal.fire({
                icon: "error",
                title: "User Not Found!",
                showConfirmButton: false,
                timer: 2000,
              })
            } else {
              Swal.fire({
                icon: "error",
                title: "Invalid Credentials!",
                showConfirmButton: false,
                timer: 2000,
              })
            }
            console.log("Response data:", error.response.data)
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
  },
};
</script>


<style scoped>
.loginbtn {
  background: #0167FF;
}
</style>
