import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import Vue3Storage from "vue3-storage";
import PrimeVue from 'primevue/config'

import 'primevue/resources/themes/tailwind-light/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import Tooltip from 'primevue/tooltip'

const app = createApp(App)

app.use(router);
app.use(Vue3Storage);
app.use(PrimeVue);
app.directive('tooltip', Tooltip);

app.mount('#app');

import "bootstrap/dist/js/bootstrap.js";
