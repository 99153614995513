<template>
    <h3 class="py-3 text-center text-3xl font-bold dark:text-dark">Archived Job Details</h3>
    <div class="d-flex align-items-center justify-content-center">
    <div class="w-full max-w-xxl px-2">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                          <div class="row">
                              <div class="col-md-6 left">
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="employerName"
                                    >
                                      Employer Name :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.employerName"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="category"
                                    >
                                      Category :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.category"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="ageLimit"
                                    >
                                      Age Limit :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.ageLimit"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="experience"
                                    >
                                      Experience :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.experience"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="experienceTime"
                                    >
                                      Experience Time:
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.experienceTime"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="additionalCourse"
                                    >
                                      Additional Course:
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.additionalCourse"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="gender"
                                    >
                                      Gender :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.gender"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="salary"
                                    >
                                      Salary :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.salary"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="workType"
                                    >
                                      Work Type :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.workType"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="companyName"
                                    >
                                      CompanyName :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.title"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="location"
                                    >
                                      Location :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.location"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="jobType"
                                    >
                                      Job Type :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.jobType"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="joinedDate"
                                    >
                                      Joined Date :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.created"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="interviewCity"
                                    >
                                      Interview City :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.interviewCity"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="interviewType"
                                    >
                                      Interview Type:
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.interviewType"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="hrName"
                                    >
                                      HR Name :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.hrName"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="hrMobile"
                                    >
                                      HR Number :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.hrMobile"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="plan"
                                    >
                                      Plan :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.plan"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 right">

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="employerId"
                                    >
                                      Employer ID :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                    v-model="jobs.employerId"
                                    class="w-full text-gray-700 bg-transparent"
                                    type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="education"
                                    >
                                      Education :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.education"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="stream"
                                    >
                                      Stream :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.stream"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="experienceJobtitle"
                                    >
                                      Experience Job Title :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.experienceJobtitle"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="language"
                                    >
                                      Language :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.language"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="additionalSkills"
                                    >
                                      Additional Skills:
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.additionalSkills"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="benifits"
                                    >
                                      Benefits :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.benifits"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="salaryType"
                                    >
                                      Salary Type :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.salaryType"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="shift"
                                    >
                                      Shift :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.shift"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="jobId"
                                    >
                                      Job ID :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.jobId"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="status"
                                    >
                                      Status :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.status"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="expiryDate"
                                    >
                                      Expiry Date :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.expiryDate"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="canCall"
                                    >
                                      Can Call? :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.canCall"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="interviewLocation"
                                    >
                                      Interview Location :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.interviewLocation"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="description"
                                    >
                                      Description :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.description"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>




                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="hrMail"
                                    >
                                      HR Mail :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.hrMail"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="payments"
                                    >
                                      Payments :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.payments"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="transactionId"
                                    >
                                      Transaction ID :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.transactionId"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                      </form>

                      <div>
          <div class="flex items-center justify-center py-2">
          <button v-on:click="onDeleteArchivedjob()"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            style="background: #0167FF;"
          >
            Delete
          </button>
        </div>
        </div>
                      
    </div>
    </div>
    <div>
      </div>
</template>

<script>

    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        name: "App",
        data() {
            return {
                jobs: {
                    name: "",
                    email: "",
                    mobile: "",
                    city: "",
                    education: "",
                    gender: "",
                    dob: "",
                },
            };
        },
        methods: {
            async onDeleteArchivedjob(userID) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.delete(`https://jobsu.in/adminapis/removePost.php/${this.$route.params.userID}`)
                        .then(() => {
                        Swal.fire("Deleted!", "Record has been deleted.", "success");
                        this.$router.push({path: '/employees'})
                        });
                    }
                })
                .catch(error=>{
                    if (error.response) {
                    console.log('Server responded with status code:', error.response.status);
                    console.log('Response data:', error.response.data);
                    } else if (error.request) {
                    console.log('No response received:', error.request);
                    } else {
                    console.log('Error creating request:', error.message);
                    }
                });
            },
        },
        async mounted() {
            this.id = this.$route.params.jobId;
          let params = new URLSearchParams();
          params.append('jobId', this.id );
            const result = await axios.post('https://jobsu.in/adminapis/getJobDetails.php', params)
            .then((response) => {
                    console.log(response.data[0]);
                    this.jobs = response.data[0];
                }).catch(error=>{
                    if (error.response) {
                    console.log('Server responded with status code:', error.response.status);
                    console.log('Response data:', error.response.data);
                    } else if (error.request) {
                    console.log('No response received:', error.request);
                    } else {
                    console.log('Error creating request:', error.message);
                    }
                });
        },
    };

</script>