<template>
    <div class="container-fluid px-5 bg-light">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container px-5">
      <button class="navbar-toggler shadow-none border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        <span>
          <i class='bx bx-menu-alt-left' style="font-size: 28px; color: #000;"></i>
        </span>
      </button>
      <a class="navbar-brand mx-auto" href="/"><img src="@/assets/logo.svg" class="w-30 h-10" alt="" /></a>
      <div class="sidebar offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header text-white">
          <div class="mx-auto">
            <img src="@/assets/logo.svg" class="w-30 h-10" alt="" />
          </div>
          <button type="button" class="btn-close btn-close-black shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body flex-lg-row p-4 p-lg-0">
          <ul class="navbar-nav justify-content-center flex-grow-1 pe-3">
            <li class="nav-item mx-2">
                <a class="nav-link" href="/">Home</a>
              </li>
              <li class="nav-item mx-2">
                <a class="nav-link" href="/#candidate">Candidates</a>
              </li>
              <li class="nav-item mx-2">
                  <a class="nav-link" href="/#employers">Employers</a>
              </li>
              <li class="nav-item mx-2">
                  <a class="nav-link" href="/#tutors">Tutors</a>
              </li>
          </ul>
          <div class="px-2"><a href="/">
              <button type="button" class="btn cls_button px-3"><i class="fas fa-mobile-alt"></i> Download App</button>
              </a>
          </div>
        </div>
      </div>
    </div>
      </nav>
    </div>
    <div class="container px-5">
      <section class="hero_section d-flex align-items-center justify-content-center py-5">
          <div class="container px-5">
              <div class="row">
                  <div class="col-md-6 d-flex flex-column justify-content-center py-5">
                      <div class="hero_description">
                          <h1 class="display-6">Jobss<span style="color: #F26419;">.co.in</span></h1>
                          <h1 class="display-3">The <span style="color: #F26419;">Online</span> Job Portal</h1>
                          <h6>Employers - Candidates - Tutors</h6>
                          <div class="pt-5">
                              <button type="button" class="btn cls_button px-3"><i class="fas fa-mobile-alt"></i> Download App</button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6 ">
                  </div>
              </div>
          </div>
      </section>
      <section class="floating-icons">
        <div class="icons">
            <ul>
                <div class="py-2">
                    <a href="#" target="_blank" aria-label="Support" style="text-decoration: none;">
                        <li class="support">
                            <span class="test rotated">Support</span>
                        </li>
                    </a>
                </div>
            </ul>
        </div>
    </section>
    <section>
        <div class="container-fluid pt-5 pb-5">
            <div class="row menu-cards" style="background-color: #fff;">
                <div class="col-md-4 py-3 d-flex align-items-center justify-content-center">
                    <a href="/#candidate">
                        <button type="button" class="btn menu_button px-3 py-2">Candidates</button>
                    </a>
                </div>
                <div class="col-md-4 py-3 d-flex align-items-center justify-content-center">
                    <a href="/#employers">
                        <button type="button" class="btn menu_button px-3 py-2">Employers</button>
                    </a>
                </div>
                <div class="col-md-4 py-3 d-flex align-items-center justify-content-center">
                    <a href="/#tutors">
                        <button type="button" class="btn menu_button px-3 py-2">Tutors</button>
                    </a>
                </div>
            </div>
            <div class="container">
                <div>
                    <h2 class="text-center pt-5 pb-3 display-6">Trending Job <span style="color: #F26419;">Categories</span></h2>
                    <div class="row pt-2 menu-card">
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">IT Solutions</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">Accounts & Finance</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">Teacher/ Tutors</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">Constructions</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">Management</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">Sales Marketing</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">Consultancy/ Brokerage</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title text-center py-1">Media</h5>
                                </div>
                            </div>
                        </div>
                        <h2 class="text-center">Download the App to Know More Features <i class="fas fa-arrow-right text-dark px-3 py-3"></i></h2>
                    </div>
                </div>
                <div id="candidate">
                    <h4 class="text-center pt-5 pb-2 display-6">Candidates</h4>
                    <h6 class="text-center pb-3">Find the best candidates for <span style="color: #F26419;">your work</span></h6>
                    <div class="row">
                        <div class="col-md-6 mb-3 d-flex flex-column justify-content-center mb-5">
                            <div class="hero_description">
                                <div style="border-left: 3px solid #0167FF;">
                                    <h6 class="px-2">Finding the right job faster in jobss app</h6>
                                </div>
                                <ul class="list-unstyled">
                                    <li><i class="fas fa-arrow-right px-3 py-3" style="color: #F26419; !important;"></i> Download the App</li>
                                    <li><i class="fas fa-arrow-right px-3 py-3" style="color: #F26419; !important;"></i> Create Your Account</li>
                                    <li><i class="fas fa-arrow-right px-3 py-3" style="color: #F26419; !important;"></i> Create Your Resume</li>
                                    <li><i class="fas fa-arrow-right px-3 py-3" style="color: #F26419; !important;"></i> Search Jobs</li>
                                </ul>
                                <div class="d-flex mt-3">
                                     <div class="mb-3 shadow-md rounded-5">
                                            <a href="https://play.google.com/store/apps/details?id=com.jobsu.jobsuapp"><img src="@/assets/googleplay.png" class="w-30 h-10" alt="" /></a>
                                        </div>
                                        <div class="ms-3 mb-3 shadow-md rounded-5">
                                            <a href="https://apps.apple.com/us/app/jobss/id6475388479"><img src="@/assets/appstore.png" class="w-30 h-10" alt="" /></a>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="text-center px-5">
                                <img src="https://ezys.in/wp-content/uploads/2023/08/2.2.png" alt="" class="img-fluid card-img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cand py-5">
                    <div class="row">
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                        <img src="@/assets/getstarted1.png"  class="candiate-img img-fluid" alt="">
                       
                      </div>
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                         <img src="@/assets/candidatehome.png" class="candiate-img img-fluid"  alt="">
                      </div>
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                        <img src="@/assets/register.png" class="candiate-img img-fluid" alt="">
                      </div>
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                        <img src="@/assets/jobdetail.png" class="candiate-img img-fluid" alt="">
                      </div>
                    </div>
                </div>
                <div id="employers">
                    <h2 class="text-center pb-2 display-6">Employers</h2>
                    <h6 class="text-center pb-3">Find the best candidates for <span style="color: #F26419;">your work</span></h6>
                    <div class="row flex-wrap-reverse">
                        <div class="col-md-6 mb-3">
                            <div>
                                <img src="https://ezys.in/wp-content/uploads/2023/08/1.1.png" alt="" class="img-fluid card-img">
                            </div>
                        </div>
                        <div class="col-md-6 mb-3 d-flex flex-column justify-content-center mb-5">
                            <div class="hero_description">
                                <div style="border-left: 3px solid #0167FF;">
                                    <h6 class="px-2">Jobss made finding the right candidates for your Companies/Enterprises easy and fast</h6>
                                </div>
                                <ul class="list-unstyled">
                                    <li><i class="fas fa-arrow-right px-3 py-3" style="color: #F26419; !important;"></i> Download the App</li>
                                    <li><i class="fas fa-arrow-right px-3 py-3" style="color: #F26419; !important;"></i> Create Your Account</li>
                                    <li><i class="fas fa-arrow-right px-3 py-3" style="color: #F26419; !important;"></i> Post Job in simple steps</li>
                                </ul>
                               <div class="d-flex mt-3">
                                     <div class="mb-3 shadow-md rounded-5">
                                            <a href="https://play.google.com/store/apps/details?id=com.jobsu.jobsu_employer"><img src="@/assets/googleplay.png" class="w-30 h-10" alt="" /></a>
                                        </div>
                                        <div class="ms-3 mb-3 shadow-md rounded-5">
                                            <a href="https://apps.apple.com/in/app/jobss-employer/id6475390998"><img src="@/assets/appstore.png" class="w-30 h-10" alt="" /></a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-5">

                    <div class="row">
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                        <img src="@/assets/getstarted.png"  class="candiate-img img-fluid" alt="">
                       
                      </div>
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                         <img src="@/assets/homepage.png" class="candiate-img img-fluid"  alt="">
                      </div>
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                        <img src="@/assets/postjob.png" class="candiate-img img-fluid" alt="">
                      </div>
                      <div class="col-6 col-md-3 d-flex justify-content-center py-md-0 py-3">
                        <img src="@/assets/detailpage.png" class="candiate-img img-fluid" alt="">
                      </div>
                    </div>

                </div>
                <div id="tutors">
                    <h2 class="text-center pb-2 display-6">Tutors</h2>
                    <h6 class="text-center pb-3">Push your limits. Reach out more people to <span style="color: #F26419;">share your knowledge</span></h6>
                    <div class="row">
                        <div class="col-md-6 mb-3 d-flex flex-column justify-content-center mb-5">
                            <div class="hero_description">
                                <div style="border-left: 3px solid #0167FF;">
                                    <h6 class="px-2">In jobss app Tutors can Advertise their classes. Tutor Can reach More people who willing to learn from it.</h6>
                                </div>
                                <div class="py-3">
                                    <button type="button" class="btn cls_button px-3"><i class="fas fa-mobile-alt"></i> Contact Us</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="text-center px-5">
                                <img src="https://ezys.in/wp-content/uploads/2023/08/Employer-min.jpg" alt="" class="img-fluid card-img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="container pt-3 app">
              <h1 class="display-6 text-center pt-3 pb-5">App UI</h1>
        <div class="row">
            <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-header p-0">
                      <img src="@/assets/SS3.png" class="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-header p-0">
                        <img src="@/assets/SS4.png" class="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-header p-0">
                        <img src="@/assets/SS5.png" class="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-header p-0">
                        <img src="@/assets/SS6.png" class="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div> -->
        </div>
    </section>
    </div>
    <div class="container-fluid p-0" style="background-color: #0167FF;">
      <div class="hero_section d-flex align-items-center justify-content-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <div class="splashscreen1 d-flex justify-content-center py-3 pt-5">
                                  <img src="@/assets/splashscreen.png" class="splashscreen" alt="" />
                                  
                                </div>
                            </div>
                            <div class="col-md-6 d-flex flex-column justify-content-center mb-5">
                                <div class="hero_description d-flex flex-column align-items-center justify-content-center py-3">
                                    <h1 class="display-4 px-5 py-2 text-white">Download Jobss</h1>
                                    <p class="pb-1 px-5" style="font-size: 18px; color: #fff;">Available in Play Store and App Store</p>
                                    <p class="text-white  px-5">by <span style="font-size: 18px;"><b>Jobsjunction innovation LLP</b></span></p>
                                    <!-- <div class="d-flex">
                                        <div class="px-3 mb-3">
                                            <a href="#"><img src="@/assets/googleplay.png" class="w-30 h-10" alt="" /></a>
                                        </div>
                                        <div class="px-3 mb-3">
                                            <a href="#"><img src="@/assets/appstore.png" class="w-30 h-10" alt="" /></a>
                                        </div>
                                    </div> -->
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
    <div style="background-color: #fff;">&nbsp;</div>
    <div class="container-fluid p-0">
      <footer style="background-color: #0167FF; color: #fff;">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center">
                    <div class="d-flex">
                        <div>
                            <a href="/privacy-policy" class="px-2" style="text-decoration: none; color: #fff;">Privacy Policy</a>
                        </div>
                        <div>|</div>
                        <div>
                            <a href="/terms&conditions" class="px-2" style="text-decoration: none; color: #fff;">Terms & Conditions</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center">
                    <div class="d-flex">
                        <div>
                            <a href="https://www.facebook.com/Firstclass.org.in?mibextid=D4KYlr" class="px-2"><i class='bx bxl-facebook'></i></a>
                        </div>
                        <div>
                            <a href="https://twitter.com/firstclassorgin?t=Dx0BBkhbs0WYulhbvBy-Lg&s=09" class="px-2"><i class='bx bxl-twitter' ></i></a>
                        </div>
                        <div>
                            <a href="https://instagram.com/jobsu.in?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" class="px-2"><i class='bx bxl-instagram' ></i></a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/firstclass-org-in/" class="px-2"><i class='bx bxl-linkedin' ></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center">
                    Copyright © 2023 jobss.co.in. All Rights Reserved.
                </div>
            </div>
        </div>
    </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style>
    body{
        font-family: 'Mulish', sans-serif;
    }
    img {
        width: 100%;
    }
    .card-img {
        width: 480px;
    }
    .splashscreen {
        float: right !important;
    }
    .splashscreen1 img{
      width: 300px;
        height: 520px;
    }
    .floating-icons .icons{
        top: 43%;
        left: 0px;
        transform: translateY(-50%);
        position: fixed;
        z-index: 100;
    }
    .floating-icons .icons ul{
        padding: initial;
    }
    .floating-icons .icons ul li{
        height: 128px;
        width: 40px;
        border-radius: 0px;
        list-style-type: none;
        text-align: center;
        padding-top: 26px;
        color: #fff;
    }
    .floating-icons .support{
        background: #0167FF;
        border: 1px solid #fff !important;
        font-size: 20px;
    }
    .test {
        writing-mode: vertical-lr;
    }
    .rotated {
        transform: rotate(180deg);
    }
    .menu-cards {
        box-shadow: 0 3px 10px -2px rgba(0,0,0,0.2);
        /* border-top-right-radius: 50px;
        border-top-left-radius: 50px; */
    }
    .menu_button {
        background: #fff;
        color: #000;
        box-shadow: 0 3px 10px -2px rgba(0,0,0,0.5) !important;
        border-radius: 20px;
    }
    .menu-card .card {
        width: 300px;
        border: none;
        box-shadow: 0 3px 10px -2px rgba(0,0,0,0.2) !important;
        border-radius: 20px;
    }
    .cls_button {
        background-color: #0167FF !important;
        color: #fff;
        border: none;
        border-radius: 20px;
    }
    .cls_button:hover {
        background-color: #0167FF !important;
        color: #fff;
        border: none;
        border-radius: 20px;
    }
    .download_button {
        background-color: #0167FF !important;
        color: #fff;
        border: none;
        border-radius: 5px;
    }
    .download_button:hover {
        color: #fff;
    }
    .bx {
        font-size: 22px;
        color: #fff;
    }
    .app img {
      max-width: 400px;
      max-height: 400px;
    }
    .nav-link {
      font-size: 20px !important;
      color: #000;
    }
.candiate-img{
    max-width: 80%;
    height: 95%;
}   

</style>
