<template>
  <div
    class="d-flex align-items-center justify-content-center vh-100 bg-gray-50 login"
  >
    <div class="w-full max-w-sm px-2">
      <h1 class="text-center text-3xl font-bold dark:text-dark py-3">Change Username</h1>
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="email1"
          >
            Old Username
          </label>
          <input
            v-model="old_email"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email1"
            type="text"
            placeholder="Old Username"
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="email2"
          >
            New Username
          </label>
          <input
            v-model="new_email"
            class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="email2"
            type="text"
            placeholder="New Username"
          />
        </div>
        <div class="mb-1">
            <p class="float-end">
              <router-link :to="`/change_password`">
                <a href="#" class="text-indigo-600 hover:text-indigo-900" style="color: #000;">Change Password?</a>
              </router-link>
            </p>
        </div><br>
        <div class="flex items-center justify-center pt-3">
          <button
            v-on:click="changeUsername()"
            class="w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            style="background: #0167FF;"
          >
            Change Username
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "App",
  data() {
    return {
      old_email: "",
      new_email: "",
    };
  },
   methods: {
    async changeUsername() {
      axios
        .post("https://jobsu.in/adminapis/changeUsername.php", {
          old_email: this.old_email,
          new_email: this.new_email,
        },
        { 
          headers: {
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        })
        .then((res) => {
            console.log(res);
          this.$router.push({ path: "/dashboard" });
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Username has been changed!",
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            if(error.response.status == 400){
              Swal.fire({
                icon: "error",
                title: "User Not Found!",
                showConfirmButton: false,
                timer: 2000,
              })
            } else {
              Swal.fire({
                icon: "error",
                title: "Invalid Credentials!",
                showConfirmButton: false,
                timer: 2000,
              })
            }
            console.log("Response data:", error.response.data)
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
  },
};
</script>

<style>

</style>