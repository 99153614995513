<template>
  <div>
    <div class="container py-5 px-5">
        
        <h3 class="text-center pb-3">Privacy Policy</h3>
        <div>
            <P>JOBSJUNCTION INNOVATION LLP ("us", "we", or "our") operates the jobsu.in website and mobile Application (hereinafter referred to as the "Service").</P>
            <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. </p>  
            <p>We use your data to provide and improve the Service. By using our Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>   
            
        </div>
        <h3>Information Collection and Use</h3>
        <div>  
           <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
        </div>
        <h3>Types of Data Collected</h3>
        <div>
            <p>Personal Data, Education and work experiences data, company data.</P>
               <P> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
                </p>
                <p> •   Email address</p>
                   <p> •	First name and last name</p>
                   <p> •	Phone number</p>
                  <p>  •	Address, State, Province, ZIP/Postal code, City</p>
                   <p> •	Cookies and Usage Data</p>
                   <p> •	Education information.</p>
                   <p> •	Work experience. </p>
                   <p> •	Company details. Etc.</p>
                    
        </div>
        <h3>Financial Data</h3>
        <div>
            <P>While using our payment gateway services, the mobile Application or the payment gateway service providers may ask you to provide with certain financial information for processing the payments. </P>
          </div>
        <h3>Usage Data</h3>
        <div>
            <p>We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
           <p>Jobsu.in does not sell or share email addresses and personal information of visitors to other companies or organizations.</p>
        </div>
        <h3>Tracking & Cookies Data</h3>
        <div>
            <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
      <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from our website and form Mobile Application and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service.</p>
    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. You can learn more how to manage cookies in the Browser Cookies Guide.</p>   
    <p>Examples of Cookies we use:</p>
       <p> •	Session Cookies. We use Session Cookies to operate our Service.</p>
       <p> •	Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</p>
       <p> •	Security Cookies. We use Security Cookies for security purposes.
        </p>
        <p> Our Web host's server automatically recognizes only the domain name, but not the individual email address, of visitors to our website or Mobile Application.</p>
        <p>Jobsu.in collects from visitors to our Mobile Application and website their domain name (but not the individual email address), aggregate information on the Web pages that they access or visit, and information volunteered by the visitor, such as survey responses and registrations. (The individual email addresses of those who send us email are available to us, but these are used only for the purpose specified in the email communication.)</p>
<p>We use cookies to store visitors' preferences, to record activity at the website and Mobile Application in order to provide better service when visitors return to our site, to ensure that visitors are not repeatedly sent the same ads, and to customize Web page content based on visitors' browser type or other information that the visitor sends.</p>
<p>If you do not want to receive email from us, let us know by writing to us at the above email address. Please specify NO EMAIL in your email subject.</p>
<p>On-line Communications: If you provide us with your postal address on-line, you will receive only the information that you specify. If you provide your telephone number on-line, you will receive telephone contact only regarding orders you have placed (or registrations) on-line.</p>
<p>JOBSJUNCTION INNOVATION LLP reserves the right to change our policy regarding how visitor information is used. If our information usage policy changes, the changes will be posted here. We will use for the new purposes only data collected after the policy change. If you are concerned about how your information will be used, check this privacy policy for changes when you visit our website and Mobile Application.</p>
<p>Upon written request, we will provide you with the information (e.g., name, address, phone number) that we maintain about you. Consumers can request this information by writing to the email address jobsuin23@gmail.com.</p>
</div>
<h3> Use of Data</h3>
<div>
    <p>Jobsu.in uses the collected data for various purposes:</p>
    <p>•	To provide and maintain the Service as per the website and Mobile Application</p>
      <p>  •	To notify you about changes to our Service</p>
       <p> •	To allow you to participate in interactive features of our Service when you choose to do so</p>
       <p> •	To provide customer care and support</p>
        <p>•	To provide analysis or valuable information so that we can improve the Service</p>
       <p> •	To monitor the usage of the Service</p>
       <p> •	To detect, prevent and address technical issues
        </p>    
        <p>The information we collect is used for internal review in order to improve the content and layout of our website and Mobile Application, to customize the content and/or layout of website and Mobile Application for individual visitors, and to notify visitors about updates of our Mobile Application. This information is not shared with, or sold to, other organizations for commercial or other purposes.</p>  
</div>
<h3>Transfer Of Data</h3>
<div>
<p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
<p>If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.</p>
<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
<p>Jobsu.in will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
</div>
<h3>Disclosure Of Data</h3>
<div>
<p>Legal Requirements</p>
<p>Jobsu.in may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
<p>Jobsu.in may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
<p>    •	To comply with a legal obligation</p>
   <p> •	To protect and defend the rights or property of jobsu.in</p>
   <p> •	To prevent or investigate possible wrongdoing in connection with the Service</p>
   <p> •	To protect the personal safety of users of the Service or the public</p>
    <p>•	To protect against legal liability</p>
    
</div>
<h3>Security Of Data</h3>
<div>
<p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
<p>When we request certain types of sensitive information, such as health or financial information, they will be performed using a secure server. Other transactions regarding sensitive information will also be performed using a secure server. </p>
<p>If you feel that this website and Mobile Application is not following this privacy policy, you may contact us at the addresses or telephone numbers provided.</p>
</div> 
<h3>Service Providers</h3>
<div>
    <p>We may employ third party companies, payment gateways and individuals to facilitate our Service or provide payment services ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our Service is used.</p>
<p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose other than the designated purpose</p>
</div>
<h3>Analytics</h3>
<div>
    <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
</div>
<h3>Links To Other Sites</h3>
<div>
<p>Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
 <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>  
</div>
<h3>Children's Privacy</h3>
<div>
<p>Our Service does not address anyone under the age of 18 ("Children").</p>
<p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
</div>
<h3>Changes To This Privacy Policy</h3>
<div>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      <p> We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
      <p> You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>   
</div>
<h3>Contact Us</h3>
<div>
<p>If you have any questions about this Privacy Policy, please contact us:</p>
 <p>   •	By email: jobsuin23@gmail.com
    </p></div></div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>